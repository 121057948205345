<template>
    <div
        v-show="carrinhoQtdReservados > 0"
        :key="carrinhoQtdReservados"
        class="checkout-container51"
        :style="
            'background-color: ' +
            (rota == 'Checkout' && carrinhoQtdReservados > 0
                ? $config.dados.corsecundaria
                : $config.dados.corprimaria)
        "
        v-if="
            $route.name != 'Checkout' ||
            ($route.name == 'Checkout' && $config.mobile()) ||
            991 > $config.responsive()
        "
    >
        <div class="checkout-container52">
            <div class="checkout-container53">
                <span
                    class="checkout-text42"
                    :style="
                        'color:' +
                        (rota == 'Checkout' && carrinhoQtdReservados > 0
                            ? 'black'
                            : 'white')
                    "
                    >{{ $t_("total") }}</span
                >
                <div
                    class="checkout-container54"
                    v-if="
                        Math.abs(
                            Number($carrinho.resumo.valor) -
                                Number($carrinho.resumo.valor_cupom)
                        ) > 0
                    "
                >
                    <span
                        class="checkout-text45"
                        :style="
                            'color:' +
                            (rota == 'Checkout' && carrinhoQtdReservados > 0
                                ? 'black'
                                : 'white')
                        "
                        >{{ Number($carrinho.resumo.valor) | moeda }}</span
                    >
                    <!-- <span class="checkout-text45" :style="'color:' + (rota == 'Checkout' && carrinhoQtdReservados  > 0 ? 'black' : 'white')">{{ (Number($carrinho.resumo.valor) - Number($carrinho.resumo.valor_cupom)) | moeda }}</span> -->
                    <span
                        class="checkout-text47"
                        :style="
                            'color:' +
                            (rota == 'Checkout' && carrinhoQtdReservados > 0
                                ? 'black'
                                : 'white')
                        "
                        >,{{ Number($carrinho.resumo.valor) | centavos }}</span
                    >
                    <!-- <span class="checkout-text47" :style="'color:' + (rota == 'Checkout' && carrinhoQtdReservados  > 0 ? 'black' : 'white')">,{{ (Number($carrinho.resumo.valor) - Number($carrinho.resumo.valor_cupom)) | centavos }}</span> -->
                </div>
                <div class="checkout-container54" v-else>
                    <span
                        class="checkout-text45"
                        :style="
                            'color:' +
                            (rota == 'Checkout' && carrinhoQtdReservados > 0
                                ? 'black'
                                : 'white')
                        "
                        >{{ Number($carrinho.resumo.valor) | moeda }}</span
                    >
                    <span
                        class="checkout-text47"
                        :style="
                            'color:' +
                            (rota == 'Checkout' && carrinhoQtdReservados > 0
                                ? 'black'
                                : 'white')
                        "
                        >,{{ Number($carrinho.resumo.valor) | centavos }}</span
                    >
                </div>
            </div>
            <div class="checkout-container55">
                <v-btn
                    class="checkout-button2 button animate__animated animate__pulse animate__infinite infinite"
                    v-if="rota == 'Checkout' && carrinhoQtdReservados > 0"
                    @click="$carrinho.pagar()"
                    >{{ $t_("finalizar_pedido") }}</v-btn
                >
                <v-btn
                    class="checkout-button2 button"
                    :class="
                        carrinhoQtdReservados > 0
                            ? 'animate__animated animate__pulse animate__infinite infinite'
                            : ''
                    "
                    :to="{ name: 'Checkout' }"
                    v-else
                    >{{ $t_("meu_carrinho") }}
                    <v-badge
                        v-if="carrinhoQtdReservados"
                        :color="$config.dados.corquaternaria"
                        dark
                        :content="carrinhoQtdReservados"
                    ></v-badge
                ></v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            rota: "",
            carrinhoQtdReservados: this.$carrinho.qtdReservada,
        };
    },
    watch: {
        $route(e) {
            const self = this;
            setTimeout(() => {
                self.rota = e.name;
            }, 200);
        },
        "$carrinho.qtdReservada"(value) {
            this.carrinhoQtdReservados = value;
        },
    },
    async mounted() {
        const self = this;
        this.carrinhoQtdReservados =
            await this.$carrinho.getCarrinhoQtdReservados();

        setTimeout(() => {
            self.rota = self.$route.name;
        }, 500);
    },
};
</script>
<style>
.v-badge__badge {
    color: black !important;
    margin: 5px;
}
</style>

<style scoped>
.checkout-container51 {
    top: auto;
    flex: 0 0 auto;
    left: auto;
    right: 0px;
    width: 100%;
    bottom: 0px;
    display: flex;
    z-index: 5;
    position: fixed;
    align-self: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.checkout-container52 {
    top: auto;
    flex: 0 0 auto;
    left: auto;
    right: 16px;
    width: 991px;
    bottom: 543px;
    display: flex;
    z-index: 999;
    align-self: center;
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
}

.checkout-container53 {
    width: 50%;
    display: flex;
    padding: 10px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.checkout-container54 {
    flex: 0 0 auto;
    width: 50%;
    display: flex;
    padding: 0px;
    flex-wrap: wrap;
    align-self: flex-start;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}

.checkout-container55 {
    width: 50%;
    height: 100%;
    display: flex;
    padding: 10px;
    align-self: center;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-start;
}

.checkout-text42 {
    color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    margin-right: 5px;
    text-transform: uppercase;
}

.checkout-text43 {
    color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    margin-right: 5px;
}
.checkout-text45 {
    color: #ffffff;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
}
.checkout-text47 {
    color: #ffffff;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
}

@media (max-width: 991px) {
    .checkout-container52 {
        width: 100%;
    }
    .checkout-container55 {
        align-self: center;
    }
    .checkout-container54 {
        width: 100%;
    }
}
</style>
