var render = function render(){var _vm=this,_c=_vm._self._c;return (
        _vm.$route.name != 'Checkout' ||
        (_vm.$route.name == 'Checkout' && _vm.$config.mobile()) ||
        991 > _vm.$config.responsive()
    )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.carrinhoQtdReservados > 0),expression:"carrinhoQtdReservados > 0"}],key:_vm.carrinhoQtdReservados,staticClass:"checkout-container51",style:('background-color: ' +
        (_vm.rota == 'Checkout' && _vm.carrinhoQtdReservados > 0
            ? _vm.$config.dados.corsecundaria
            : _vm.$config.dados.corprimaria))},[_c('div',{staticClass:"checkout-container52"},[_c('div',{staticClass:"checkout-container53"},[_c('span',{staticClass:"checkout-text42",style:('color:' +
                    (_vm.rota == 'Checkout' && _vm.carrinhoQtdReservados > 0
                        ? 'black'
                        : 'white'))},[_vm._v(_vm._s(_vm.$t_("total")))]),(
                    Math.abs(
                        Number(_vm.$carrinho.resumo.valor) -
                            Number(_vm.$carrinho.resumo.valor_cupom)
                    ) > 0
                )?_c('div',{staticClass:"checkout-container54"},[_c('span',{staticClass:"checkout-text45",style:('color:' +
                        (_vm.rota == 'Checkout' && _vm.carrinhoQtdReservados > 0
                            ? 'black'
                            : 'white'))},[_vm._v(_vm._s(_vm._f("moeda")(Number(_vm.$carrinho.resumo.valor))))]),_c('span',{staticClass:"checkout-text47",style:('color:' +
                        (_vm.rota == 'Checkout' && _vm.carrinhoQtdReservados > 0
                            ? 'black'
                            : 'white'))},[_vm._v(","+_vm._s(_vm._f("centavos")(Number(_vm.$carrinho.resumo.valor))))])]):_c('div',{staticClass:"checkout-container54"},[_c('span',{staticClass:"checkout-text45",style:('color:' +
                        (_vm.rota == 'Checkout' && _vm.carrinhoQtdReservados > 0
                            ? 'black'
                            : 'white'))},[_vm._v(_vm._s(_vm._f("moeda")(Number(_vm.$carrinho.resumo.valor))))]),_c('span',{staticClass:"checkout-text47",style:('color:' +
                        (_vm.rota == 'Checkout' && _vm.carrinhoQtdReservados > 0
                            ? 'black'
                            : 'white'))},[_vm._v(","+_vm._s(_vm._f("centavos")(Number(_vm.$carrinho.resumo.valor))))])])]),_c('div',{staticClass:"checkout-container55"},[(_vm.rota == 'Checkout' && _vm.carrinhoQtdReservados > 0)?_c('v-btn',{staticClass:"checkout-button2 button animate__animated animate__pulse animate__infinite infinite",on:{"click":function($event){return _vm.$carrinho.pagar()}}},[_vm._v(_vm._s(_vm.$t_("finalizar_pedido")))]):_c('v-btn',{staticClass:"checkout-button2 button",class:_vm.carrinhoQtdReservados > 0
                        ? 'animate__animated animate__pulse animate__infinite infinite'
                        : '',attrs:{"to":{ name: 'Checkout' }}},[_vm._v(_vm._s(_vm.$t_("meu_carrinho"))+" "),(_vm.carrinhoQtdReservados)?_c('v-badge',{attrs:{"color":_vm.$config.dados.corquaternaria,"dark":"","content":_vm.carrinhoQtdReservados}}):_vm._e()],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }